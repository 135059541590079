import React from "react";
import LoadeerSvg from "../../../../public/content/805.svg";
import { usePromiseTracker } from "react-promise-tracker";
const LoaderComponent = ({ showLoading = false }) => {
    const { promiseInProgress } = usePromiseTracker(null);
    //////Show Loader while action column is hide before/after print dialog/////
    return (React.createElement("div", { style: {
            display: showLoading || promiseInProgress === true ? "block" : "none",
        }, className: "loading-animation-wrapper" },
        React.createElement("img", { src: LoadeerSvg, className: "loading-animation", alt: "Loading..." })));
};
export default LoaderComponent;
