var _a;
import { SessionVariables } from "@core/constants/SessionVariables";
import { SessionStorage } from "@core/helpers/localStorage/sessionStorage";
const { getKey } = SessionStorage;
const { DONATION_VIEW_CART_ITEMS, DONATION_VIEW_COMPANY_INFORMATION } = SessionVariables;
const COMPANY = getKey(DONATION_VIEW_COMPANY_INFORMATION);
const CART_ITEMS = (_a = getKey(DONATION_VIEW_CART_ITEMS)) !== null && _a !== void 0 ? _a : [];
export const DEFAULT_CHECKOUT_DONATIONS = {
    company: COMPANY,
    donationItems: CART_ITEMS
};
