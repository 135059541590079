import { faBuilding, faChartBar, faUser, faFileAlt, faHome, } from "@fortawesome/free-solid-svg-icons";
import { activeSummaryIcon, inActiveSummaryIcon } from "@assets/img";
import { UserType } from "@core/enums/UserTypes";
export const sidebarActions = [
    {
        title: "Dashboard",
        routing: "dashboard",
        icon: faHome,
        svgIcon: {
            active: "ActiveDashboardMenu",
            inActive: "InActiveDashboardMenu",
        },
        requiredRoles: [UserType.Admin],
        order: 10,
    },
    {
        title: "Companies",
        routing: "companies",
        icon: faBuilding,
        svgIcon: {
            active: "ActiveCompanyMenu",
            inActive: "InActiveCompanyMenu",
        },
        requiredRoles: [UserType.Admin, UserType.AccountManager],
        order: 20,
    },
    {
        title: "Users",
        routing: "users",
        icon: faUser,
        svgIcon: {
            active: "ActiveUserMenu",
            inActive: "InActiveUserMenu",
        },
        order: 40,
        requiredRoles: [UserType.Admin],
    },
    {
        title: "Items",
        routing: "items",
        requiredRoles: [UserType.Admin, UserType.AccountManager],
        icon: faChartBar,
        svgIcon: {
            active: "ActiveTrackItemMenu",
            inActive: "InActiveTrackItemMenu",
        },
        order: 30,
    },
    {
        title: "Reports",
        routing: "summaryReports",
        icon: faFileAlt,
        svgIcon: {
            active: "ActiveFinancialReportsMenu",
            inActive: "InActiveFinancialReportsMenu",
        },
        order: 50,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
        //status: treeMenuType.collapse,
        sub: [
            {
                title: "Summary report",
                routing: "summaryReports",
                icon: null,
                svgIcon: {
                    active: activeSummaryIcon,
                    inActive: inActiveSummaryIcon,
                },
                // svgIcon: "SiteMenu",
                requiredRoles: [],
            },
            {
                title: "Donor report",
                routing: "donorReports",
                icon: null,
                svgIcon: {
                    active: activeSummaryIcon,
                    inActive: inActiveSummaryIcon,
                },
                requiredRoles: [],
            },
            {
                title: "Corporate report",
                routing: "corporateReports",
                icon: null,
                svgIcon: {
                    active: activeSummaryIcon,
                    inActive: inActiveSummaryIcon,
                },
                requiredRoles: [],
            },
            {
                title: "Campaign report",
                routing: "campaignReports",
                icon: null,
                svgIcon: {
                    active: activeSummaryIcon,
                    inActive: inActiveSummaryIcon,
                },
                requiredRoles: [],
            },
        ],
    },
];
export const getSortedMenus = () => {
    const menus = [...sidebarActions];
    const sorted = menus.sort((a, b) => (a.order > b.order ? 1 : -1));
    return sorted;
};
