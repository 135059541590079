export const INDEX_ROUTE = "/";
export const USERS_ROUTE = "/vertical/users";
export const COMPANIES_ROUTE = "/vertical/companies";
export const lOGIN_ROUTE = "/public/sign-in";
export const RESET_PASSWORD_ROUTE = "/public/reset-password";
export const FORGOT_PASSWORD_ROUTE = "/public/forget-password";
export const NOT_FOUND_ROUTE = "/public/page-404";
export const UNAUTHORIZED_ROUTE = "/public/unauthorized";
export const DASHBOARD_ROUTE = "/vertical/dashboard";
export const ITEMS_ROUTE = "/vertical/items";
export const MOBILE_THANK_YOU_ROUTE = "/donor/thankyou";
