import React from "react";
import "./App.scss";
import { ApiErrorHandler } from "@layout/components/errors/apiErrorHandler";
import Loader from "@layout/components/loading/loader";
import { NotificationContainer } from "@layout/components/notifications";
import UserRoutes from "@layout/components/routes/UserRoutes";
function App() {
    return (React.createElement(ApiErrorHandler, null,
        React.createElement(UserRoutes, null),
        React.createElement(NotificationContainer, null),
        React.createElement(Loader, null)));
}
export default App;
