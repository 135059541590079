import { GET_ITEMS_SUCCESS, ADD_ITEM_SUCCESS, UPDATE_ITEM_SUCCESS, DELETE_ITEM_SUCCESS, INACTIVATE_ITEM_SUCCESS, ACTIVATE_ITEM_SUCCESS, CLEAR_ALL_ITEMS_SUCCESS, } from "./types";
const initialState = [];
export const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ITEMS_SUCCESS: {
            return action.payload;
        }
        case ADD_ITEM_SUCCESS: {
            return [action.payload, ...state];
        }
        case UPDATE_ITEM_SUCCESS: {
            const items = state.map((item) => item.id !== action.payload.id
                ? item
                : Object.assign(Object.assign({}, action.payload), { key: Math.random() }));
            return items;
        }
        case DELETE_ITEM_SUCCESS: {
            const items = state.filter((item) => item.id !== action.payload);
            return items;
        }
        case ACTIVATE_ITEM_SUCCESS: {
            const items = state.map((item) => {
                if (item.id === action.payload) {
                    return Object.assign(Object.assign({}, item), { status: 1 });
                }
                return item;
            });
            return items;
        }
        case INACTIVATE_ITEM_SUCCESS: {
            const items = state.map((item) => {
                if (item.id === action.payload) {
                    return Object.assign(Object.assign({}, item), { status: 0 });
                }
                return item;
            });
            return items;
        }
        case CLEAR_ALL_ITEMS_SUCCESS: {
            return [];
        }
        default: {
            return state;
        }
    }
};
