import { UserType } from "@core/enums/UserTypes";
import MobileThankYouContainer from "@views/global/donor/mobile/components/MobileCheckoutForm/components/MobileThankYou";
import { lazy } from "react";
const PublicLayout = lazy(() => import(
/* webpackChunkName: "publicLayout" */ "@layout/publicLayout/publicLayout"));
const DonationCheckoutContainer = lazy(() => import(
/* webpackChunkName: "DonationCheckoutContainer" */ "@views/global/donor/checkout"));
const DonationsContainer = lazy(() => import(
/* webpackChunkName: "DonationsContainer" */ "@views/global/donor/donations"));
const ForgotPasswordContainer = lazy(() => import(
/* webpackChunkName: "ForgotPasswordContainer" */ "@views/global/ForgotPassword/forgotPasswordContainer").then((module) => ({ default: module.ForgotPasswordContainer })));
const LoginContainer = lazy(() => import(
/* webpackChunkName: "LoginContainer" */ "@views/global/login/LoginContainer"));
const ResetPasswordAfterFPassword = lazy(() => import(
/* webpackChunkName: "ResetPasswordAfterFPassword" */ "@views/global/ResetPassword/resetPasswordAfterFPassword"));
const UnAuthorized = lazy(() => import(/* webpackChunkName: "UnAuthorized" */ "@views/global/sessions/401"));
const CampaignReportsContainer = lazy(() => import(
/* webpackChunkName: "CampaignReportsContainer" */ "@views/internal/admin/reports/campaignReports"));
const CorporateReportsContainer = lazy(() => import(
/* webpackChunkName: "CorporateReportsContainer" */ "@views/internal/admin/reports/corporateReports"));
const DonorReportsContainer = lazy(() => import(
/* webpackChunkName: "DonorReportsContainer" */ "@views/internal/admin/reports/donorReports"));
const SummaryReportsContainer = lazy(() => import(
/* webpackChunkName: "SummaryReportsContainer" */ "@views/internal/admin/reports/summaryReports"));
const Companies = lazy(() => import(
/* webpackChunkName: "Companies" */ "@views/internal/shared/companies"));
const Dashboard = lazy(() => import(
/* webpackChunkName: "Dashboard" */ "@views/internal/shared/dashboard"));
const UserListingContainer = lazy(() => import(
/* webpackChunkName: "UserListingContainer" */ "@views/internal/admin/users/userListing"));
const ItemListingContainer = lazy(() => import(
/* webpackChunkName: "ItemListingContainer" */ "@views/internal/shared/items/itemListing"));
const VerticalLayout = lazy(() => import(/* webpackChunkName: "VerticalLayout" */ "@layout/vertical/Vertical"));
const DonarLayout = lazy(() => import(/* webpackChunkName: "DonarLayout" */ "@layout/donarLayout"));
export const defaultRoutes = [
    {
        path: "dashboard",
        component: Dashboard,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin],
    },
    {
        path: "companies",
        component: Companies,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
    },
    {
        path: "users",
        component: UserListingContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin],
    },
    {
        path: "items",
        component: ItemListingContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
    },
    {
        path: "donations/:companyId",
        component: DonationsContainer,
        exact: true,
        layout: "donor",
        layoutName: DonarLayout,
        requiredRoles: [],
    },
    {
        path: "checkout",
        component: DonationCheckoutContainer,
        exact: true,
        layout: "donor",
        layoutName: DonarLayout,
        requiredRoles: [],
    },
    {
        path: "thankyou",
        component: MobileThankYouContainer,
        exact: true,
        layout: "donor",
        layoutName: DonarLayout,
        requiredRoles: [],
    },
    {
        path: "summaryReports",
        component: SummaryReportsContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
    },
    {
        path: "donorReports",
        component: DonorReportsContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
    },
    {
        path: "corporateReports",
        component: CorporateReportsContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
    },
    {
        path: "campaignReports",
        component: CampaignReportsContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredRoles: [UserType.Admin, UserType.AccountManager],
    },
    {
        path: "sign-in",
        component: LoginContainer,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredRoles: [],
    },
    {
        path: "forget-password",
        component: ForgotPasswordContainer,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredRoles: [],
    },
    {
        path: "reset-password",
        component: ResetPasswordAfterFPassword,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredRoles: [],
    },
    {
        path: "unauthorized",
        component: UnAuthorized,
        exact: true,
        layout: "public",
        layoutName: VerticalLayout,
        requiredRoles: [],
    },
];
export const getRoutesByRole = (userRole) => {
    return defaultRoutes.filter(({ requiredRoles }) => !(requiredRoles === null || requiredRoles === void 0 ? void 0 : requiredRoles.length) || (requiredRoles === null || requiredRoles === void 0 ? void 0 : requiredRoles.includes(userRole)));
};
