/* eslint-disable */
export const ErrorCode = {
    UnhandledException: '400',
    Unauthenticated: '1',
    Unauthorized: '2',
    RouteNotFound: '3',
    EntityNotFound: '4',
    ValidationFailed: '5',
    ConcurrentModification: '6',
    InvalidResetPasswordToken: '7',
    EmailNotFound: '8',
    DuplicatedGroupFiltersName: '9',
    DuplicatedUserName: '10',
    InvalidAccountConfirmationToken: '11',
    RegisterManagersNotFound: '12',
    InvalidStateTransition: '13',
    DuplicateBandName: '20',
    DuplicatedEmail: '25',
    ChangeRequestAlreadyApproved: '30',
    ChangeRequestAlreadyRejected: '31',
    ChangeRequestAlreadySentToRepair: '32',
    MyRequestAlreadySentForApproval: '33',
    ChangeRequestDueDatePast: '34',
    ChangeRequestInitialTaskStatusChanged: '35',
    IncorrectRequestTypeForRepair: '36',
    DuplicatedRefNo: '37',
    DuplicatedNhsNo: '38',
    DuplicatedNiNo: '39',
    ChangeRequestAlreadyExistForEntity: '40',
    InitialImportFailed: '41',
    RunningTaskAlreadyMissed: '42',
    MasterTaskTemplateToDeleteNotInDraftStatus: '43',
    MasterTaskToDeleteNotInDraftStatus: '44',
    internalServerError: '500'
};
