import { USER_ACTIVE, USER_INACTIVE } from "@core/constants/UserConstants";
import { GET_USERS_SUCCESS, ADD_USER_SUCCESS, UPDATE_USER_SUCCESS, DELETE_USER_SUCCESS, INACTIVATE_USER_SUCCESS, ACTIVATE_USER_SUCCESS, UNLOCK_USER_SUCCESS, } from "./types";
const initialState = [];
export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_SUCCESS: {
            return action.payload;
        }
        case ADD_USER_SUCCESS: {
            return [action.payload, ...state];
        }
        case UPDATE_USER_SUCCESS: {
            const users = state.map((user) => user.id !== action.payload.id
                ? user
                : action.payload);
            return users;
        }
        case DELETE_USER_SUCCESS: {
            const users = state.filter((user) => user.id !== action.payload);
            return users;
        }
        case ACTIVATE_USER_SUCCESS: {
            const users = state.map((user) => {
                if (user.id === action.payload) {
                    return Object.assign(Object.assign({}, user), { status: USER_ACTIVE });
                }
                return user;
            });
            return users;
        }
        case INACTIVATE_USER_SUCCESS: {
            const users = state.map((user) => {
                if (user.id === action.payload) {
                    return Object.assign(Object.assign({}, user), { status: USER_INACTIVE });
                }
                return user;
            });
            return users;
        }
        case UNLOCK_USER_SUCCESS: {
            const users = state.map((user) => {
                if (user.id === action.payload) {
                    return Object.assign(Object.assign({}, user), { status: USER_ACTIVE });
                }
                return user;
            });
            return users;
        }
        default: {
            return state;
        }
    }
};
