import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SessionVariables } from "@core/constants/SessionVariables";
import { SessionStorage } from "@core/helpers/localStorage/sessionStorage";
import { FacebookSvg, FareshareLogoSvg, InstagramSvg, LinkedInSvg, TwitterSvg, } from "@layout/components/icons/iconsSvg";
import { ReactGA } from "src/config/gaConfig";
import "./styles.scss";
const FARESHARE_LINKEDIN_LINK = "https://www.linkedin.com/company/fareshareuk/";
const FARESHARE_FACEBOOK_LINK = "https://www.facebook.com/UKFareShare/";
const FARESHARE_TWITTER_LINK = "https://twitter.com/FareShareUK";
const FARESHARE_INSTAGRAM_LINK = "https://www.instagram.com/fareshareuk/";
const FARESHARE_URL = "https://fareshare.org.uk/";
const MobileThankYouContainer = () => {
    useEffect(() => {
        ReactGA.send("pageview");
    }, []);
    const IconLink = ({ link }) => {
        switch (link) {
            case FARESHARE_LINKEDIN_LINK: {
                return (React.createElement("a", { href: link },
                    React.createElement(LinkedInSvg, null)));
            }
            case FARESHARE_FACEBOOK_LINK:
                return (React.createElement("a", { href: link },
                    React.createElement(FacebookSvg, null)));
            case FARESHARE_TWITTER_LINK:
                return (React.createElement("a", { href: link },
                    React.createElement(TwitterSvg, null)));
            case FARESHARE_INSTAGRAM_LINK:
                return (React.createElement("a", { href: link },
                    React.createElement(InstagramSvg, null)));
        }
    };
    const history = useHistory();
    useEffect(() => {
        const handleBackArrowClickPage = (event) => {
            event.preventDefault();
            history.replace(`/donor/donations/${SessionStorage.getKey(SessionVariables.DONATIONS_COMPANY_HASH_KEY)}`);
        };
        window.addEventListener("popstate", (event) => handleBackArrowClickPage(event), {
            once: true,
        });
        return () => {
            window.removeEventListener("popstate", (event) => handleBackArrowClickPage(event));
        };
    }, []);
    return (React.createElement("div", { className: "mobile-thank-you-container" },
        React.createElement("div", { className: "mobile-thank-fareshare-logo" },
            React.createElement(FareshareLogoSvg, null)),
        React.createElement("div", { className: "mobile-thank-you-title" }, "Thank you for donating"),
        React.createElement("div", { className: "mobile-thank-you-text" }, "Your support is enabling us to increase the amount of food distributed and in doing so, reach many more vulnerable people at risk of going hungry."),
        React.createElement("div", { className: "mobile-thank-you-text" }, "Our work is only possible because of people like you by our side."),
        React.createElement("div", { className: "mobile-thank-you-text" }, "All donations will help FareShare source and deliver more surplus food, similar to the items in your basket, for our frontline charities and people facing food insecurity."),
        React.createElement("div", { className: "mobile-thank-you-follow-us" }, "Follow us"),
        React.createElement("div", { className: "mobile-thank-you-icons-group" },
            React.createElement(IconLink, { link: FARESHARE_LINKEDIN_LINK }),
            React.createElement(IconLink, { link: FARESHARE_TWITTER_LINK }),
            React.createElement(IconLink, { link: FARESHARE_FACEBOOK_LINK }),
            React.createElement(IconLink, { link: FARESHARE_INSTAGRAM_LINK })),
        React.createElement("a", { className: "mobile-thank-you-fareshare-url", href: FARESHARE_URL }, "fareshare.org.uk")));
};
export default MobileThankYouContainer;
