import { combineReducers } from "redux";
import { settingsReducer } from "./settings/reducer";
import { contextReducer } from "./context/reducer";
import { rootViewReducer } from "./rootView/reducer";
import { companyReducer } from "./company/reducer";
import { initialState as initialStoreState } from "./initialState";
import { USER_LOGGED_OUT } from "./context/types";
import { searchInputReducer } from "./listingSearchInput/reducer";
import { userReducer } from "./user/reducer";
import { itemReducer } from "./item/reducer";
import { checkoutDonationsReducer } from "./checkoutDonations/reducer";
const appReducer = combineReducers({
    context: contextReducer,
    settings: settingsReducer,
    rootView: rootViewReducer,
    companies: companyReducer,
    users: userReducer,
    items: itemReducer,
    searchInput: searchInputReducer,
    checkoutDonations: checkoutDonationsReducer,
});
const rootReducer = (state, action) => {
    if (action.type === USER_LOGGED_OUT) {
        state = initialStoreState;
    }
    return appReducer(state, action);
};
export default rootReducer;
