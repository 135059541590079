import { UserType } from "../enums/UserTypes";
import { DASHBOARD_ROUTE, COMPANIES_ROUTE } from "./../constants/routesPath";
export class defaultRoute {
    static navigateRoute(history, userRole) {
        history.push(defaultRoute.DEFAULT_ROUTE[userRole]);
    }
    static getNavigateRoute(userRole) {
        return defaultRoute.DEFAULT_ROUTE[userRole];
    }
}
defaultRoute.DEFAULT_ROUTE = {
    [UserType.Admin]: DASHBOARD_ROUTE,
    [UserType.AccountManager]: COMPANIES_ROUTE,
};
