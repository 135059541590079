var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getRoutesByRole } from "../../../routing/routes";
import PrivateRoute from "../../../routing/privateRoute";
import NotFound from "@views/global/sessions/404";
import { useSelector } from "react-redux";
import LoaderComponent from "../loading/loader";
const UserRoutes = () => {
    const userType = useSelector((state) => { var _a; return (_a = state.context) === null || _a === void 0 ? void 0 : _a.userType; });
    const userRoutesByRole = getRoutesByRole(userType);
    const renderSingleRoute = (route) => {
        var _a;
        return (React.createElement(AppRoute, { key: route.path, exact: true, path: route.layout.length > 0
                ? `/${route.layout}/${route.path}`
                : `/${route.path}\``, layoutName: route.layoutName, layout: route.layout, component: route.component, routeComponent: ((_a = route === null || route === void 0 ? void 0 : route.requiredRoles) === null || _a === void 0 ? void 0 : _a.length) ? PrivateRoute : Route }));
    };
    const AppRoute = (_a) => {
        var { component: Component, layoutName: LayoutName, layout: Layout, routeComponent: RouteComponent = Route } = _a, rest = __rest(_a, ["component", "layoutName", "layout", "routeComponent"]);
        return (React.createElement(RouteComponent, Object.assign({}, rest, { render: (props) => (React.createElement(React.Suspense, { fallback: React.createElement(LoaderComponent, { showLoading: true }) },
                React.createElement(LayoutName, null,
                    React.createElement(React.Suspense, { fallback: React.createElement(LoaderComponent, { showLoading: true }) },
                        React.createElement(Component, Object.assign({}, props)))))) })));
    };
    return (React.createElement(Switch, null,
        userRoutesByRole.map((route) => {
            return renderSingleRoute(route);
        }),
        React.createElement(Redirect, { from: "/", exact: true, to: "/public/sign-in" }),
        React.createElement(Route, { component: NotFound })));
};
export default UserRoutes;
