export class ApiError {
    constructor(response, code, id, payload) {
        this.response = response;
        this.code = code;
        this.id = id;
        this.payload = payload;
    }
}
export function isExpected(ex, codes) {
    return ex instanceof ApiError && codes.length !== 0 && codes.some(code => ex.code === code);
}
export function isUnauthenticatedError(ex) {
    return isExpected(ex, ["201"]);
}
