import React from "react";
import BaseErrorPage from "./BaseErrorPage";
import { Button } from "antd";
import { HomeFilled } from "@ant-design/icons";
import { useHistory } from "react-router";
import { defaultRoute } from "@core/utils/defaultRoute";
import { useDispatch, useSelector } from "react-redux";
import { SessionStorage } from "@core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "@core/constants/SessionVariables";
import { lOGIN_ROUTE } from "@core/constants/routesPath";
import isEmpty from "@core/helpers/isEmpty";
import { logoutActions } from "@redux/context/actions";
import useDidMount from "@core/hooks/useDidMount";
import PublicLayout from "@layout/publicLayout/publicLayout";
export default function NotFound() {
    const history = useHistory();
    const dispatch = useDispatch();
    const userContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
    const context = useSelector((state) => {
        return state.context;
    });
    useDidMount(() => {
        //session storage is clear by other tab action and redux state exist for current tab
        if ((context === null || context === void 0 ? void 0 : context.isAuthenticated) && isEmpty(userContext)) {
            dispatch(logoutActions(history));
        }
    });
    function NavigateHome() {
        if (!isEmpty(userContext)) {
            // history.push(defaultRoute.getRouteByPermissions(userContext));
            defaultRoute.navigateRoute(history, userContext === null || userContext === void 0 ? void 0 : userContext.userType);
            return;
        }
        history.replace(lOGIN_ROUTE);
    }
    return (React.createElement(PublicLayout, null,
        " ",
        React.createElement(BaseErrorPage, { subTitle: React.createElement("h6", { className: "text-md text-center" }, "Sorry! The page you were looking for doesn't exist."), bg: `${window.origin}/content/404-page.jpg`, action: React.createElement(Button, { type: "primary", onClick: NavigateHome, style: { width: "auto", position: "relative", bottom: "1rem" }, icon: React.createElement(HomeFilled, { className: "ml-0 mr-2", style: { fontSize: "1em" } }) }, "Back to Home"), title: React.createElement("h1", { style: { fontSize: "6rem" }, className: "text-color-300 text-center mb-2" },
                React.createElement("i", { className: "icofont-radio-active color-error mr-2", style: { opacity: 0.5 } }),
                "404") })));
}
