export class SessionStorage {
    static setKey(key, value, storageMedia = localStorage) {
        storageMedia.setItem(key, JSON.stringify(value));
    }
    static getKey(key, storageMedia = localStorage) {
        const sessionValue = storageMedia.getItem(key);
        try {
            return JSON.parse(sessionValue);
        }
        catch (error) {
            return sessionValue;
        }
    }
    static removeKey(key, storageMedia = localStorage) {
        storageMedia.removeItem(key);
    }
    static clearStorage() {
        sessionStorage.clear();
        return localStorage.clear();
    }
}
