import { CLEAR_SEARCH_INPUT, GET_SEARCH_INPUT, } from "./types";
const initialState = null;
export const searchInputReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SEARCH_INPUT: {
            return action.payload;
        }
        case CLEAR_SEARCH_INPUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
