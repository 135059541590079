import { DEFAULT_SETTINGS } from "./settings/settings";
import { DEFAULT_CONTEXT } from "./context/context";
import { DEFAULT_ROOTVIEW } from "./rootView/rootView";
import { DEFAULT_CHECKOUT_DONATIONS } from "./checkoutDonations/sessionValues";
export const initialState = {
    settings: DEFAULT_SETTINGS,
    context: DEFAULT_CONTEXT,
    rootView: DEFAULT_ROOTVIEW,
    companies: [],
    users: [],
    items: [],
    searchInput: null,
    checkoutDonations: DEFAULT_CHECKOUT_DONATIONS,
};
