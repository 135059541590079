import { GET_COMPANY_SUCCESS, ADD_COMPANY_SUCCESS, ACTIVATE_COMPANY_SUCCESS, INACTIVATE_COMPANY_SUCCESS, UPDATE_COMPANY_SUCCESS, } from "./types";
const initialState = [];
export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_SUCCESS: {
            return action.payload;
        }
        case ADD_COMPANY_SUCCESS: {
            return [action.payload, ...state];
        }
        case UPDATE_COMPANY_SUCCESS: {
            const companies = state.map((company) => company.id !== action.payload.id
                ? company
                : Object.assign(Object.assign({}, action.payload), { key: Math.random() }));
            return [...companies];
        }
        case ACTIVATE_COMPANY_SUCCESS: {
            const companies = state.map((company) => {
                if (company.id === action.payload) {
                    return Object.assign(Object.assign({}, company), { status: 1 });
                }
                return company;
            });
            return companies;
        }
        case INACTIVATE_COMPANY_SUCCESS: {
            const companies = state.map((company) => {
                if (company.id === action.payload) {
                    return Object.assign(Object.assign({}, company), { status: 0 });
                }
                return company;
            });
            return companies;
        }
        default: {
            return state;
        }
    }
};
