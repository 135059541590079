var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AccountApi } from "@core/api/accountApi";
import { SET_CONTEXT, CLEAR_CONTEXT, USER_LOGGED_OUT } from "./types";
import { SessionStorage } from "@core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "@core/constants/SessionVariables";
import { lOGIN_ROUTE } from "@core/constants/routesPath";
import isEmpty from "@core/helpers/isEmpty";
export const setContext = (data) => ({
    type: SET_CONTEXT,
    payload: data,
});
export const clearContext = () => ({
    type: CLEAR_CONTEXT,
});
export const handleError = () => ({
    type: CLEAR_CONTEXT,
});
export const logout = () => ({
    type: USER_LOGGED_OUT,
});
export function signIn(user) {
    // return function (dispatch) {
    return AccountApi.signIn(user)
        .then((response) => {
        if (response.jwt !== "") {
            SessionStorage.setKey(SessionVariables.AUTH_TOKEN, response.jwt);
        }
        return response;
    })
        .catch((error) => {
        throw error;
    });
    // };
}
export function logoutActions(history) {
    return function (dispatch, getState) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // saveContext();
            const { isAuthenticated } = (_a = getState()) === null || _a === void 0 ? void 0 : _a.context;
            const sessionContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
            //session storage clear by logout on other tab and for current tab redux state is persist
            if (isAuthenticated && isEmpty(sessionContext)) {
                return dispatch(clearLogoutSession(history));
            }
            return AccountApi.logout().then((response) => {
                if (response === null || response === void 0 ? void 0 : response.ok) {
                    return dispatch(clearLogoutSession(history));
                }
            });
        });
    };
}
export function clearLogoutSession(history) {
    return function (dispatch) {
        SessionStorage.clearStorage();
        dispatch(clearContext());
        history.replace(lOGIN_ROUTE);
        dispatch(logout());
        return window.location.reload();
    };
}
export function loadContext() {
    return function (dispatch) {
        return AccountApi.loadContext()
            .then((response) => {
            if (response) {
                SessionStorage.setKey(SessionVariables.USER_CONTEXT, response);
                dispatch(setContext(response));
            }
            return response;
        })
            .catch((error) => {
            throw error;
        });
    };
}
