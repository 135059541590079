import { DashboardType } from "@core/enums/dashboardType";
import { getSortedMenus } from "./../../layout/components/menu/DashboardMenus";
export const DEFAULT_ROOTVIEW = {
    pageTitle: "",
    dashboardType: DashboardType.Admin,
    sectionName: "",
    sideBarActions: getSortedMenus(),
    sectionActions: [],
    showScrollToTopButton: true,
};
