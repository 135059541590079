import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
const containerId = "default";
const customId = "custom-id-yes";
const defaultOptions = {
    autoClose: 5000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: true,
    pauseOnHover: true,
    containerId: containerId,
    className: "notifications-container",
    toastClassName: "notification-toast",
};
export const NotificationContainer = () => (React.createElement(ToastContainer, Object.assign({ enableMultiContainer: true }, defaultOptions)));
export const notifications = {
    errorCode(code, options) {
        toast.error(code, {
            containerId,
        });
    },
    error(messageKey, options) {
        toast.error(messageKey, Object.assign({ containerId, toastId: customId }, options));
    },
    warning(messageKey, options) {
        toast.warn(messageKey, Object.assign({ containerId }, options));
    },
    success(messageKey, options) {
        toast.success(messageKey, Object.assign({ containerId }, options));
    },
};
export const handleApiMessages = (response, data) => {
    if (response.ok) {
        if (data.message) {
            notifications.success(data.message);
        }
    }
    else if ((data === null || data === void 0 ? void 0 : data.code) && data.hasOwnProperty("message")) {
        notifications.error(data.message ? data.message : "Something went wrong!");
    }
};
