export const DEFAULT_SETTINGS = {
    boxed: true,
    loaded: false,
    layout: "vertical",
    sidebarBg: "#fbfbfb",
    sidebarColor: "#1F2022",
    sidebarOpened: false,
    topbarBg: "#ffffff",
    topbarColor: "#1F2022",
    modalHeaderBg: "#17a99a",
    // modalHeaderClr: "#ffffff",
    // modalHeaderBg: "white",
    // modalHeaderClr: "#1f202280",
};
