export class SessionVariables {
}
SessionVariables.SELECTED_TRACKED_ITEM_INDEX = "SELECTED_TRACKED_ITEM_INDEX";
SessionVariables.SELECTED_TRACKED_ITEM_TYPE = "SELECTED_TRACKED_ITEM_TYPE";
SessionVariables.SELECTED_LOOKUP_TYPE = "SELECTED_LOOKUP_TYPE";
SessionVariables.SELECTED_TILE_INDEX = "SELECTED_TILE_INDEX";
SessionVariables.SITE_OVERVIEW_DATA = "SITE_OVERVIEW_DATA";
SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB = "SELECTED_MANAGE_TRACKED_ITEM_TAB";
SessionVariables.SELECTED_USER_LISTING_TAB = "SELECTED_USER_LISTING_TAB";
SessionVariables.SELECTED_NOTIFICATIONS_LISTING_TAB = "SELECTED_NOTIFICATIONS_LISTING_TAB";
SessionVariables.SELECTED_REPORTS_LISTING_TAB = "SELECTED_REPORTS_LISTING_TAB";
SessionVariables.AUTH_TOKEN = "AUTH_TOKEN";
SessionVariables.PI_TOKEN = "PI_TOKEN";
SessionVariables.GROUP = "GROUP";
SessionVariables.COMPANY = "COMPANY";
SessionVariables.USER_CONTEXT = "USER_CONTEXT";
SessionVariables.SELECTED_TASK = "SELECTED_TASK";
SessionVariables.SITE_STATUSES = "SITE_STATUSES";
SessionVariables.PASSWORD_EXPIRES_IN = "password_expires_in";
SessionVariables.OLD_PASSWORD = "oldPassword";
SessionVariables.USERNAME = "username";
SessionVariables.USER_FILTER = "USER_FILTER";
SessionVariables.SELECTED_TRACKED_ITEM_DASHBOARD_RATING = "SELECTED_TRACKED_ITEM_DASHBOARD_RATING";
SessionVariables.SELECTED_CHART_FILTER = "SELECTED_CHART_FILTER";
SessionVariables.BREADCRUMB = "BREADCRUMB";
SessionVariables.QUERY_PARAMS = "QUERY_PARAMS";
SessionVariables.SELECTED_SUB_CATEGORY = "SELECTED_SUB_CATEGORY";
SessionVariables.SELECTED_CATEGORY = "SELECTED_CATEGORY";
SessionVariables.MENU_SESSION = "MENU_SESSION";
SessionVariables.LISTING_FILTERS = "LISTING_FILTERS";
SessionVariables.RECENT_NOTIFICATIONS = "RECENT_NOTIFICATIONS";
SessionVariables.COMPANY_ASSIGNED_SESSION = "COMPANY_ASSIGNED_SESSION";
SessionVariables.USER_GUIDE_VISIBLE = "USER_GUIDE_VISIBLE";
SessionVariables.DONATION_VIEW_COMPANY_INFORMATION = "DONATION_VIEW_COMPANY_INFORMATION";
SessionVariables.DONATION_VIEW_CART_ITEMS = "DONATION_VIEW_CART_ITEMS";
SessionVariables.DONATIONS_VIRTUAL_MODE = "DONATIONS_VIRTUAL_MODE";
SessionVariables.DONATIONS_COMPANY_HASH_KEY = "DONATIONS_COMPANY_HASH_KEY";
SessionVariables.SHELF_VIEW_SELECTED_SHELF = "SHELF_VIEW_SELECTED_SHELF";
SessionVariables.SHELF_VIEW_POPUP_VISIBLE = "SHELF_VIEW_POPUP_VISIBLE";
