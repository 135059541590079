var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { SessionStorage } from "@core/helpers/localStorage/sessionStorage";
const PrivateRoute = (_a) => {
    var { render: Component } = _a, rest = __rest(_a, ["render"]);
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.context.isAuthenticated);
    const getRole = SessionStorage.getKey("role");
    return (React.createElement(Route, Object.assign({}, rest), isAuthenticated ? (React.createElement(Component, null)) : (React.createElement(Redirect, { to: { pathname: "/public/sign-in", state: { from: location } } }))));
};
export default PrivateRoute;
