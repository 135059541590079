import { ADD_DONATION_ITEM, REMOVE_DONATION_ITEM, UPDATE_DONATION_ITEM_QUANTITY, GET_COMPANY_INFORMATION_SUCCESS, CLEAR_ALL_DONATION_ITEMS, CLEAR_COMPANY_AND_DONATION_ITEMS } from "./types";
const initialState = {
    company: {
        id: undefined,
        companyName: undefined,
        companyImage: undefined
    },
    donationItems: []
};
export const checkoutDonationsReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case GET_COMPANY_INFORMATION_SUCCESS: {
            return { company: Object.assign({}, action.payload), donationItems: [...state.donationItems] };
        }
        case ADD_DONATION_ITEM: {
            //increment quantity if exist already otherwise add new
            const prevDonationItems = [...state.donationItems];
            const updatedCartItems = prevDonationItems.some(({ id }) => id === action.payload.id) ?
                prevDonationItems.map((donationItem) => {
                    var _a, _b;
                    return donationItem.id !== (action.payload).id
                        ? donationItem
                        : Object.assign(Object.assign({}, donationItem), { quantity: (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : donationItem.quantity + 1 });
                }) :
                [Object.assign(Object.assign({}, action.payload), { quantity: (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1 }), ...prevDonationItems];
            return Object.assign(Object.assign({}, state), { donationItems: updatedCartItems });
        }
        case UPDATE_DONATION_ITEM_QUANTITY: {
            const prevDonationItems = [...state.donationItems];
            const updateDonationItems = prevDonationItems.map((donationItem) => donationItem.id !== (action.payload).id
                ? donationItem
                : Object.assign(Object.assign({}, donationItem), { quantity: action.payload.quantity }));
            return Object.assign(Object.assign({}, state), { donationItems: [...updateDonationItems] });
        }
        case REMOVE_DONATION_ITEM: {
            const prevDonationItems = [...state.donationItems];
            const updateDonationItems = prevDonationItems.filter((donationItem) => (donationItem === null || donationItem === void 0 ? void 0 : donationItem.id) !== action.payload);
            return Object.assign(Object.assign({}, state), { donationItems: [...updateDonationItems] });
        }
        case CLEAR_ALL_DONATION_ITEMS: {
            return Object.assign(Object.assign({}, state), { donationItems: [] });
        }
        case CLEAR_COMPANY_AND_DONATION_ITEMS: {
            return Object.assign({}, initialState);
        }
        default: {
            return state;
        }
    }
};
