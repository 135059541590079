import { GET_SIDEBAR_ACTIONS, SET_SIDEBAR_ACTIONS, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.rootView;
export const rootViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SIDEBAR_ACTIONS:
            return Object.assign({}, state);
        case SET_SIDEBAR_ACTIONS:
            return Object.assign(Object.assign({}, state), { sideBarActions: action.payload });
        default:
            return Object.assign({}, state);
    }
};
