import { SET_SETTINGS, RESET_SETTINGS, UPDATE_SETTINGS, TOGGLE_SIDEBAR, RESET_SIDEBAR, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.settings;
export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS:
            return Object.assign({}, action.payload);
        case UPDATE_SETTINGS:
            return Object.assign(Object.assign({}, state), action.payload);
        case RESET_SETTINGS:
            return Object.assign({}, initialState);
        case TOGGLE_SIDEBAR:
            return Object.assign(Object.assign({}, state), { sidebarOpened: !state.sidebarOpened });
        case RESET_SIDEBAR:
            return Object.assign(Object.assign({}, state), { sidebarOpened: false });
        default:
            return Object.assign({}, state);
    }
};
