import { SET_CONTEXT, CLEAR_CONTEXT, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.context;
const defaults = {
    isAuthenticated: false,
    name: undefined,
    picture: undefined,
    userType: undefined,
    userId: undefined
};
export const contextReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT:
            return Object.assign(Object.assign({}, action.payload), { isAuthenticated: true });
        case CLEAR_CONTEXT:
            return Object.assign({}, defaults);
        default:
            return Object.assign({}, state);
    }
};
