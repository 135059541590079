export const SET_CONTEXT = "SET_CONTEXT";
export const CLEAR_CONTEXT = "CLEAR_CONTEXT";
export const SET_CONTEXT_COMPANY = "SET_CONTEXT_COMPANY";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USERS_CHANGED = "USERS_CHANGED";
export const COMPANY_CHANGED = "COMPANY_CHANGED";
export const GROUP_CHANGED = "GROUP_CHANGED";
export const SET_CONTEXT_APPLICATION = "SET_CONTEXT_APPLICATION";
export const SET_SITE_STATUSES = "SET_SITE_STATUSES";
export const SET_CONTEXT_TASK = "SET_CONTEXT_TASK";
export const SET_CONTEXT_SUB_CATEGORY = "SET_CONTEXT_SUB_CATEGORY";
