var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { lOGIN_ROUTE } from "./../../constants/routesPath";
import { SessionStorage } from "./../../helpers/localStorage/sessionStorage";
import { ApiError } from "./apiErrors";
import { apiConstants } from "./constants";
import queryString from "query-string";
import { API_DOMAIN_PATH } from "../../../config/constants";
import { handleApiMessages, notifications, } from "@layout/components/notifications";
import { trackPromise } from "react-promise-tracker";
import { SessionVariables } from "../../constants/SessionVariables";
export const httpGet = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    if (options === null || options === void 0 ? void 0 : options.silent) {
        return yield request(url, "GET", options);
    }
    return yield trackPromise(request(url, "GET", options));
});
export const httpPost = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield trackPromise(request(url, "POST", options));
});
export const httpPut = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield trackPromise(request(url, "PUT", options));
});
export const httpDelete = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield trackPromise(request(url, "DELETE", options));
});
export const getAsFormData = (params) => {
    const formData = new FormData();
    if (params.file) {
        formData.append("file", params.file);
    }
    formData.append("requestData", JSON.stringify(params.requestData));
    return formData;
};
const request = (url, method, options) => __awaiter(void 0, void 0, void 0, function* () {
    let response;
    try {
        toggleLoading(true, options);
        const params = options && options.params
            ? `?${queryString.stringify(options.params)}`
            : "";
        let tokenHeader = {};
        var tokenString = SessionStorage.getKey(SessionVariables.AUTH_TOKEN);
        if (tokenString) {
            tokenHeader = { Authorization: "Bearer " + tokenString };
        }
        response = yield fetch(API_DOMAIN_PATH + url + params, {
            body: getBody(options),
            headers: Object.assign(Object.assign({ Accept: "application/json", 
                //  "Access-Control-Allow-Origin": "*",
                "Content-Security-Policy": "default-src 'self'", "X-Content-Type-Options": "nosniff", "X-Frame-Options": "SAMEORIGIN" }, tokenHeader), getContentTypeHeader(options)),
            method,
        });
    }
    catch (error) {
        throw error;
    }
    finally {
        toggleLoading(false, options);
    }
    const responseValue = yield handleResponse(response);
    return responseValue;
});
const getBody = (options) => {
    if (!options) {
        return undefined;
    }
    if (options.body === null ||
        typeof options.body !== "object" ||
        options.body instanceof FormData) {
        return options.body;
    }
    return JSON.stringify(options.body);
};
const getContentTypeHeader = (options) => {
    if (options === null || options === undefined) {
        return { "Content-Type": "text/plain" };
    }
    if (options.body instanceof FormData) {
        return {};
    }
    return {
        "Content-Type": typeof options.body === "object" ? "application/json" : "text/plain",
    };
};
const mapResponseToData = (response) => __awaiter(void 0, void 0, void 0, function* () {
    const contentType = response.headers.get("Content-Type");
    if (contentType === "application/json") {
        const text = yield response.text();
        return text ? JSON.parse(text) : {};
    }
    else if (contentType === "application/octet-stream") {
        return response.blob();
    }
    else {
        return yield response;
    }
});
// there are too many problems with passing non-object values - let's stick only with objects
const handleResponse = (response) => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield mapResponseToData(response);
    handleApiMessages(response, data);
    if (response.ok || (data === null || data === void 0 ? void 0 : data.code)) {
        return data;
    }
    else if (response.status === 401 &&
        window.location.pathname !== lOGIN_ROUTE) {
        SessionStorage.clearStorage();
        window.location.href = lOGIN_ROUTE;
        return;
    }
    else if (response.status >= 500) {
        notifications.error("Opps! Something went wrong!");
        return;
    }
    else {
        processFailedResponse(response);
    }
});
const processFailedResponse = (response) => {
    const errorCode = response.headers.get(apiConstants.headers.errorCode);
    const errorId = response.headers.get(apiConstants.headers.errorId);
    const payloadError = response.headers.get(apiConstants.headers.payloadError);
    throw new ApiError(response, errorCode, errorId, payloadError ? JSON.parse(payloadError) : {});
};
function toggleLoading(show, options) {
    if (!options || !options.silent) {
        if (show) {
        }
        else {
        }
    }
}
